import React from "react";
import Title from "../../../components/Title";

const TableHeader = () => {
  return (
    <div className="d-flex align-items-center justify-content-between flex-wrap">
      <Title text="Users" />
    </div>
  );
};

export default TableHeader;
