import React from "react";
import MenuItems from "./MenuItems";
import { Drawer } from "antd";
import SidebarLogo from "./SidebarLogo";
import { MdClose } from "react-icons/md";

const ResponsiveSidebar = ({ collapsed, setCollapsed, responsive, Menu }) => {
  return (
    <Drawer
      title={<SidebarLogo collapsed={!collapsed} />}
      headerStyle={{ padding: "0px 24px", background: "#EDF2F9" }}
      className="add-days-modal"
      bodyStyle={{ padding: 0, background: "#EDF2F9" }}
      placement="left"
      closeIcon={<MdClose size={24} />}
      onClose={() => setCollapsed(false)}
      
      key="left"
      width="200px"
      maskClosable={true}
      open={collapsed}
    >
      <MenuItems
        responsive={responsive}
        Menu={Menu}
        setCollapsed={setCollapsed}
      />
    </Drawer>
  );
};

export default ResponsiveSidebar;
