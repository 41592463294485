import { Tag, message } from "antd";

export const onLoginSuccess = (setConfig, navigate, data, role) => {
  if (data && data.role === role) {
    setConfig("user", data);
    localStorage.setItem("userInfo", JSON.stringify(data));
    navigate("/dashboard");
  } else {
    message.info("Your account information is not correct");
  }
};
export const renderStatus = (status) => {
  const colours = { block: "red", active: "green" };
  if (status) {
    return (
      <Tag color={colours[status]} className="text-capitalize">
        {status}
      </Tag>
    );
  }
};
export const logout = (setConfig, navigate) => {
  localStorage.removeItem("userInfo");
  localStorage.clear();
  setConfig("user", null);
  navigate("/login");
};
export const showErrorNotification = (error) => {
  if (error && error.request && error.request && error.request.response) {
    const errorResponses = error.request.response;

    if (errorResponses.status === "ERROR") {
      message.error(errorResponses.message);
    }
  } else {
    message.error(error.message);
  }
};
export const showSuccessNotification = (response) => {
  if (response && response.data && response.data.status === "SUCCESS") {
    message.success(response.data.message);
  }
};
