import React from "react";

const Title = ({ text, className }) => {
  return (
    <h4
      style={{ color: "#22181C" }}
      className={`${className} regular-font font-26 mb-0`}
    >
      {text}
    </h4>
  );
};

export default Title;
