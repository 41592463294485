import React from "react";
import SidebarLogo from "./SidebarLogo";
import MenuItems from "./MenuItems";

const SidebarMenu = ({
  collapsed,
  Layout,
  Menu,
  setCollapsed,
  setResponsive,
  responsive,
}) => {
  const { Sider } = Layout;

  return (
    <Sider
      style={{
        boxShadow: "7px 0px 16px #00000042",
        backgroundColor: "#EDF2F9",
        display: responsive ? "none" : "block",
      }}
      breakpoint="lg"
      collapsedWidth="0"
      trigger={null}
      collapsible
      collapsed={collapsed}
      onBreakpoint={(broken) => {
        setResponsive(broken);
        setCollapsed(broken);
      }}
    >
      <SidebarLogo collapsed={collapsed} />
      <MenuItems
        setCollapsed={setCollapsed}
        Menu={Menu}
        responsive={responsive}
      />
    </Sider>
  );
};

export default SidebarMenu;
