import { Avatar, Popover } from "antd";
import React, { useState } from "react";
import { GrFormDown } from "react-icons/gr";
import { HiAdjustmentsHorizontal } from "react-icons/hi2";
import { IoLogOutSharp } from "react-icons/io5";
import { useNavigate } from "react-router";
import { logout } from "../../../utils/commonFunctions";
import { useApi } from "../../../context/useApi";
const UserInfo = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { config, setConfig } = useApi();
  const { user } = config;

  const content = (
    <div onClick={() => setOpen(false)} className="d-flex flex-column">
      <span onClick={() => navigate("/setting")}>
        <HiAdjustmentsHorizontal size="20px" /> Account Setting
      </span>
      <span onClick={() => logout(setConfig, navigate)}>
        <IoLogOutSharp size="20px" /> Logout
      </span>
    </div>
  );
  return (
    <div className="me-4">
      <Avatar
        src={user && user.profileImageUrl}
        style={{ boxShadow: "0px 3px 6px #00000029" }}
        className="me-2"
      />
      <Popover
        open={open}
        onOpenChange={(e) => setOpen(e)}
        content={content}
        placement="bottomLeft"
        trigger="click"
        className="pointer text-capitalize"
      >
        <span className="userName-on-Samll"> {user && user.fullName} </span><GrFormDown />
      </Popover>
    </div>
  );
};

export default UserInfo;
