import React from "react";
const SidebarLogo = ({ collapsed }) => {
  return (
    <div
      style={{ height: "63.5px" }}
      className="text-white d-flex align-items-center justify-content-center"
    >
      <div className="d-flex gap-2 align-items-center">
        <p className="primary-color font-26 regular-font mb-0">Admin</p>
      </div>
    </div>
  );
};

export default SidebarLogo;
