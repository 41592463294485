import React from "react";
import ResetPassword from "../../../components/Modal/ResetPassword";

const Reset = ({ record }) => {
  console.log("====record====", record);
  return (
    <ResetPassword
      record={record}
      recordName="changePassword"
      apiName="changePassword"
      okText="Save"
      oldPasswordField={true}
      modalTitle="Change Password"
      resetButtonName={
        <p className="mb-0 text-decoration-underline pointer primary-color">
          Change Password
        </p>
      }
    />
  );
};

export default Reset;
