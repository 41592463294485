import React from "react";
import Table from "../../components/Table";
import Widget from "../../components/widget";
import TableHeader from "./components/TableHeader";
import UserCards from "./components/UserCards";
import moment from "moment";
const FRONT_URL = process.env.REACT_APP_FRONT_URL;
const Index = () => {
  const columns = [
    {
      title: "Name",
      render: (record) => <span className="text-capitalize">{record.fullName || "---"}</span>,
      key: "fullName",
    },
    {
      title: "Email",
      render: (record) => <span>{record.email || "---"}</span>,
      key: "email",
    },
    {
      title: "Date",
      render: (record) => (
        <span>
          {(record && record.userData && record.userData.date && moment(record.userData.date).format("YYYY-MM-DD")) ||
            "---"}
        </span>
      ),
      key: "date",
    },
    {
      title: "Swedish Time GMT+2",
      render: (record) => {
        let time = record && record.userData && record.userData.swedishTime && moment(record.userData.swedishTime);
        let formattedTime = "----";
        if (time) {
          const gmtPlus2Moment = time.utcOffset("+02:00");
          formattedTime = gmtPlus2Moment.format("HH:mm");
        }
        return <span>{formattedTime}</span>;
      },
      key: "swedishTime",
    },
    {
      title: "ip Address",
      render: (record) => <span>{(record && record.userData && record.userData.ip) || "---"}</span>,
      key: "ip",
    },
    {
      title: "Link",
      render: (record) => <a href={`${FRONT_URL}/result/${record.materialCalculationLinks}`}>View</a>,
      key: "email",
    },
  ];
  return (
    <div>
      <UserCards />
      <Widget Header={<TableHeader />}>
        <Table columns={columns} recordName="userList" apiName="getCalculations" />
      </Widget>
    </div>
  );
};

export default Index;
