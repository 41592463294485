import React from "react";
import AuthLayout from "../../../components/AuthLayout";
import LoginForm from "./components/LoginForm";
import { useApi } from "../../../context/useApi";
import { useNavigate } from "react-router-dom";
import { onLoginSuccess } from "../../../utils/commonFunctions";

const Index = () => {
  const navigation = useNavigate();
  const { setConfig, config } = useApi();
  if (config && config.loginRecord_success) {
    console.log("====config.loginRecord===", config.loginRecord);
    onLoginSuccess(setConfig, navigation, config.loginRecord, "superAdmin");
    setConfig("loginRecord_success", false);
  }
  return (
    <AuthLayout
      title="Account Login"
      content="Welcome, Login to your account to continue"
    >
      <LoginForm />
    </AuthLayout>
  );
};

export default Index;
