import React from "react";

const Widget = ({ Header, children, pt = "20px", pb = "6px", px = "24px" }) => {
  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        borderRadius: "4px",
        border: " 1px solid #E8E8E8",
      }}
    >
      <div style={{ padding: `${pt} ${px} ${pb} ${px}` }}>{Header}</div>
      <hr style={{ color: "#D9D9D9" }} className="mt-2 mb-3" />
      <div style={{ padding: "0px 10px" }}>{children}</div>
    </div>
  );
};

export default Widget;
