import React from "react";
import { Area, AreaChart, ResponsiveContainer } from "recharts";
const SmallCardGraph = ({ stroke, fill, count, desc }) => {
  const increamentData = [
    { name: "Page A", price: 200 },
    { name: "Page B", price: 1200 },
    { name: "Page C", price: 600 },
    { name: "Page D", price: 1600 },
    { name: "Page D", price: 1000 },
    { name: "Page H", price: 2260 },
    { name: "Page K", price: 800 },
  ];
  return (
    <div
      style={{
        boxShadow: "0px 0px 16px #5C5C5C1F",
        borderRadius: "8px",
        background: "#fff",
      }}
    >
      <div className="px-3 pt-3">
        <div className="ant-row-flex">
          <h2 className="mb-0 font-22 bold-font">{count}</h2>
        </div>
        <p className="mb-0 font-14 regular-font color-515151">{desc}</p>
      </div>
      <ResponsiveContainer width="100%" height={75}>
        <AreaChart
          data={increamentData}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="color4" x1="0" y1="0" x2="1" y2="0">
              <stop offset="5%" stopColor="#4ECDE4" stopOpacity={0.9} />
              <stop offset="95%" stopColor="#06BB8A" stopOpacity={0.9} />
            </linearGradient>
          </defs>
          <Area
            dataKey="price"
            type="monotone"
            strokeWidth={1}
            stackId="2"
            fillOpacity={0.8}
            stroke={stroke}
            fill={fill}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SmallCardGraph;
