import React from "react";
import { HiMenu } from "react-icons/hi";
import { Button } from "antd";
import UserInfo from "./UserInfo";
const TopBar = ({ Header, setCollapsed, collapsed, colorBgContainer }) => {
  return (
    <Header
      style={{
        padding: 0,
        background: colorBgContainer,
        boxShadow: "0px 0px 16px #0000001A",
      }}
    >
      <div className="d-flex align-items-center justify-content-between">
        <Button
          type="text"
          icon={<HiMenu size="30" />}
          onClick={() => setCollapsed(!collapsed)}
          style={{
            fontSize: "16px",
            backgroundColor: "transparent",
            width: 64,
            height: 64,
          }}
        />
        <UserInfo />
      </div>
    </Header>
  );
};

export default TopBar;
