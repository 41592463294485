import React from "react";
import AuthLayout from "../../../components/AuthLayout";
import SetNewPasswordForm from "./components/SetNewPasswordForm";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";


const Index = () => {
  const navigation = useNavigate();
  return (
    <AuthLayout
      title="Set New Password"
      content={`Set your account's new password`}
    >
      <SetNewPasswordForm />
      <div className="d-flex gap-2 mt-3 mb-5 align-items-center justify-content-center w-100">
        <BiArrowBack onClick={() => navigation("/login")} size={18} />
        <span onClick={() => navigation("/login")} className="bold-font font-14 color-111827 pointer">
          Back to log in
        </span>
      </div>
    </AuthLayout>
  );
};

export default Index;
