import React from "react";

const Index = () => {
  return (
    <div className="d-flex h-100 w-100 aling-items-center justify-content-center">
      <h1 className="mt-5 extra-bold-font" style={{ color: "#515151" }}>
        Coming soon
      </h1>
    </div>
  );
};

export default Index;
