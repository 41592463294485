import React from "react";
import SmallCardGraph from "../../../components/Graphs/SmallCardGraph";
import { Col, Row } from "antd";
import { useApi } from "../../../context/useApi";

const UserCards = () => {
  const { config } = useApi();
  const renderCard = (dec, stroke, fill, key) => {
    if (config && config.userList && config.userList.cards) {
      return (
        <SmallCardGraph
          count={
            (config.userList.cards[0] && config.userList.cards[0][key]) || 0
          }
          desc={dec}
          stroke={stroke}
          fill={fill}
        />
      );
    }
  };
  return (
    <Row className="gap-md-0 gap-3 mb-4">
      <Col xl={6} lg={8} md={8} sm={24} xs={24} className="pe-md-3">
        {renderCard("Total Users", "#C39FDF", "#F3ECF9", "total")}
      </Col>
      <Col xl={6} lg={8} md={8} sm={24} xs={24} className="pe-md-3">
        {renderCard("Total Active User", "#B8E886", "#E4F9D7", "active")}
      </Col>
      <Col xl={6} lg={8} md={8} sm={24} xs={24}>
        {renderCard("Total Block User", "#F198CA", "#FCEAF4", "block")}
      </Col>
    </Row>
  );
};

export default UserCards;
