import React from "react";
import { Form, Input, Button } from "antd";
import { useApi } from "../../../../context/useApi";
import { useNavigate } from "react-router";

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const { config, setConfig, DataRequestAction } = useApi();
  const onFinish = (values) => {
    DataRequestAction("post", "forgetPassword", values, "ForgetPassword");
  };
  if (config && config.ForgetPassword_success) {
    navigate("/login");
    setConfig("ForgetPassword_success", false);
  }

  return (
    <Form layout="vertical" className="auth-form" onFinish={onFinish}>
      <Form.Item
        label="Email"
        name="email"
        rules={[
          { required: true, message: "Email is required!" },
          { type: "email", message: "Enter valid email!" },
        ]}
      >
        <Input placeholder="Enter your email" />
      </Form.Item>
      <Button
        loading={config && config.ForgetPassword_spin}
        disabled={config && config.ForgetPassword_spin}
        htmlType="submit"
        type="primary"
        className="w-100 extra-bold-font h-40px"
      >
        Send
      </Button>
    </Form>
  );
};

export default ForgotPasswordForm;
