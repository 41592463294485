import { Button, Modal } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../../utils/commonFunctions";
import { useApi } from "../../context/useApi";
const SessionModal = () => {
  const { config, setConfig } = useApi();
  const navigate = useNavigate();
  const sessionExpFun = () => {
    setConfig("sessionExp", false);
    logout(setConfig, navigate);
  };

  return (
    <Modal
      className="p-0"
      closable={false}
      width={"400px"}
      footer={null}
      open={config && config.sessionExp}
      bodyStyle={{ padding: 0 }}
      centered
    >
      <div className="d-flex align-items-center justify-content-center py-5">
        <div className="d-flex flex-column align-items-center">
          <h4 className="mb-3">Your Session has been expire.</h4>
          <Button type="primary" onClick={() => sessionExpFun()}>
            Again Login
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SessionModal;
