import React, { useState } from "react";
import { Layout, Menu, theme } from "antd";
import SidebarMenu from "./components/SidebarMenu";
import TopBar from "./components/TopBar";
import SessionModal from "../Modal/SessionModal";
import ResponsiveSidebar from "./components/ResponsiveSidebar";
const { Header, Content } = Layout;
const Index = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [responsive, setResponsive] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Layout style={{ height: "100vh" }}>
      <SidebarMenu
        setCollapsed={setCollapsed}
        Layout={Layout}
        Menu={Menu}
        collapsed={collapsed}
        setResponsive={setResponsive}
        responsive={responsive}
      />
      <Layout>
        <TopBar
          Header={Header}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          colorBgContainer={colorBgContainer}
          responsive={responsive}
        />

        <Content
          style={{
            padding: 20,
            minHeight: 280,
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          {children}
        </Content>
      </Layout>
      <SessionModal />
      {responsive && (
        <ResponsiveSidebar
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          responsive={responsive}
          Menu={Menu}
        />
      )}
    </Layout>
  );
};

export default Index;
