import { RiDashboardFill } from "react-icons/ri";
import { HiUserCircle } from "react-icons/hi";
import { AiFillSetting } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
const MenuList = [
  {
    key: "/dashboard",
    icon: <RiDashboardFill size="18px" />,
    label: "Dashboard",
  },
  {
    key: "/user",
    icon: <HiUserCircle size="20px" />,
    label: "User",
  },
  {
    key: "/setting",
    icon: <AiFillSetting size="20px" />,
    label: "Setting",
  },
];

const MenuItems = ({ responsive, setCollapsed, Menu }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const selectedKeys = pathname.substr(1);
  let keyArray = selectedKeys.split("/");
  let defaultOpenKeys = [keyArray[0]];
  if (selectedKeys.split("/").length === 3) {
    defaultOpenKeys = [keyArray[0], keyArray[1]];
  }
  useEffect(() => {
    if (pathname === "/") {
      navigate("/dashboard");
    }
  }, [pathname, navigate]);
  return (
    <Menu
      defaultOpenKeys={defaultOpenKeys}
      selectedKeys={["/" + selectedKeys]}
      theme="dark"
      onClick={(e) => {
        if (responsive) {
          setCollapsed(false);
          navigate(e.key);
        } else {
          navigate(e.key);
        }
      }}
      style={{ backgroundColor: "#EDF2F9", color: "#515151" }}
      mode="inline"
      defaultSelectedKeys={["1"]}
      items={MenuList}
    />
  );
};

export default MenuItems;
